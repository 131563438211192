@import './default-theme-style.scss';

.SignIn-module_account-logo-title__3q_9g {
  max-height: 100px;
  max-width: 100px ;
}

.layout-wrapper .bar-layout .bar-logo {
  height: 40px;
  margin-left: 10px;
}
